/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

*{
	font-family: 'Poppins', sans-serif!important;
}


.page-body {
	background: #F4F9FC!important;
}


h5{
    margin-bottom: 10px!important
}




.btn{
    border-radius: 5px;
}

.modal-title{
    font-weight: 600
}

.modal-content{
    padding: 20px
}

//calendar

.fc .fc-col-header-cell-cushion {
    color: #000;
    text-decoration: none;
    text-align: left;
    font-weight: 600;
}

.fc-scrollgrid-sync-inner{
    text-align: left;
    padding-left: 10px;
}

.fc .fc-daygrid-day-number {
    color: #000;
    text-decoration: none
}

.fc .fc-daygrid-day.fc-day-today{
    background: #fff
}

.fc-h-event{
    border-radius: 20px;
    padding: 2.5px 5px;
    border: 0!important;
    color: #fff!important;
}

.fc-day-past{
    background: hsla(0,0%,84%,.3)!important
}